export const INITIAL_BOARD = 'motivation';
export const PHRASAL_VERBS = {
  clothes: {
    'take off': 'to remove clothing',
    'dress up': 'put formal clothes',
    'pull on': 'put on clothes quickly',
    'slip on': 'to quickly put on a piece of clothing'
  },
  emotions: {
    'calm down': 'to stop feeling angry',
    'put up sth': 'express opposition',
    'tell off sb or tell sb off': 'regañar / criticar a alguien',
    'turn out': 'be discovered surprisingly',
    'slip out': 'escaparse sin querer'
  },
  travelling: {
    'set off': 'comenar un viaje',
    'get in': 'to arrive at a destination',
    'check into': 'to arrive and get a room',
    'stop over': 'to stay for a short period',
    'take off': 'to begin to fly or leave a place',
    'get away': 'go away from home for a vacation',
    'break down': 'to stop working (vehicle)',
    'cut off (sb/sth) or cut (sb/sth) off': 'cerrar el paso',
    'book (sb) into': 'reservar habitación para alguien',
    'check in': 'arrive to hotel and get the key yo your room',
    'check out': 'leave hotel after paying your bill'
  },
  motivation: {
    'keep to sth': 'not change plans, to do what you have planned/promised',
    'keep up (with sb/sth)': 'deal with/understand something that happends fast',
    'put off sb or put sb off': 'echar para atrás a alguien',
    'work on sth': 'to spend time improving/repairing sth',
    'look up to sb': 'to admire someone',
    'go through': 'to experience a difficult situation',
    'put up with': 'to tolerate',
    'get over': 'to recover from something',
    'cut down': 'to reduce the amount of something',
    'do without': 'apañárselas sin algo',
    'face up to sth': 'afrontar dificultades',
    'get through sth': 'superar o finalizar algo',
    'go off': 'to stop liking something',
    'let off sb or let sb off': 'to not to punish someone',
    'pick up sth or pick sth up': 'aprender sin proponérselo',
    'live up to': 'to be as good as expected',
    'look forward': 'feel excited about sth is going to happen',
    'take off': 'to become popular or successful',


  },
  movement: {
    'come down': 'fall and land on the ground',
    'end up': 'finally be in a place or situation',
    'consist of sth': 'to be formed or made',
    'come up': 'move towards someone',
    'go for sth': 'to choose something',
    'go up': 'increase',
    'keep up (with sth)': 'to move at the same speed',
    'pull away': 'start moving',
    'pull up': 'when vehicle stops for a short time'
  },
  work: {
    'come up with sth': 'sugerir una idea',
    'put together': 'to assemble sth',
    'take off': 'to not work for a period of time',
    'keep on': 'to continue to do sth',
    'go over sth': 'to examine or check sth',
    'call sb up': 'to telephone sb',
    'catch up on sth': 'retomar o ponerse al día con algo',
    'fill in sth': 'to write information on an official doc',
    'find out': 'averiguar',
    'get down to sth': 'tomarse en serio algo',
    'get into sth': 'be chosen',
    'get out of': 'to avoid doing something you dislike',
    'go ahead': 'dar permiso o empezar algo',
    'go on': 'to happen',
    'keep to sth': 'not change plans, to do what you have planned/promised',
    'keep up (with sb/sth)': 'deal with/understand something that happends fast',
    'look at sth/sb': 'to examine sth/sb as an expert',
    'look for sth/sb': 'to try to find',
    'stand out': 'destacar',
    'start out': 'to begin the part of life when you work',
    'work on sth': 'to spend time improving/repairing sth',
    'look up': 'to get info from a reference source',
  },
  shopping: {
    'add to sth': 'añadir a algo',
    'come out': 'become available',
    'miss out on sth': 'to fail to use an opportunity to enjoy or get sth',
    'save up (sth)': 'ahorrar',
    'take back sth or take sth back': 'return something bought or borrowed'
  },
  family: {
    'tell off': 'regañar / criticar a alguien',
    'look up to sb': 'to admire someone',
    'take after': 'to look like someone in your family',
    'look after': 'take care of someone',
    'make up for': 'compensate for something bad',
    'fall out': 'discutir y "dejar de hablarse"',
    'go ahead': 'dar permiso o empezar algo',
    'hang around (with sb)': 'spend time with sb',
    'keep sb in': 'castigar o retener a alguien'
  },
  skills: {
    'put together': 'to assemble sth',
    'get away': 'to escape',
    'take out': 'to extract or remove',
    'come across sth': 'encontrar por casualidad',
    'work sth out': 'to find a solution to a problem by thinking about it',
    'look into': 'to examine the facts about a problem',
    'come up with sth': 'sugerir una idea',
    'do without': 'apañárselas sin algo',
    'find out': 'averiguar',
    'fit in with sth': 'combinar, emparejas o encajar cosas',
    'look at sth/sb': 'to examine sth/sb as an expert',
    'look for sth/sb': 'to try to find',
    'make out sth/sb or make sth/sb out': 'entender a duras penas',
    'send off sth or send sth off': 'to send sth by post',
    'talk sb into (doing) sth': 'to persuade someone',
    'tell off': 'regañar / criticar a alguien',
    'tell apart': 'to see a difference btw two similar things'
  },
};

export const EXAMPLE_SENTENCES = {
  'add to sth': '',
  'book (sb) into': `I've booked us into a hotel in the centre of town for three nights`,
  'break down': 'My car broken down, so I called a low truck',
  'call sb up': 'He used to call me up in the middle of the night .',
  'calm down': 'It took Kylie several hours to calm down after she saw the accident.',
  'catch up on sth': 'I have to catch up on my reading.',
  'check in': 'They said I must check in at least three hours before my flight.',
  'check into': `We are stopping over in California on Sunday, so we won't check into the hotel in Seattle until Monday`,
  'check out': 'Donna checked out of the hotel this morning.',
  'come across sth': `He came across some of his old love letters in his wife's desk.`,
  'come down': 'A lot of trees came down in the storm.',
  'come out': 'When does their new album come out?',
  'come up with sth': 'Gavin came up with the idea of dividing the rooms in half.',
  'come up': 'A young girl came up to me and asked for money.',
  'consist of sth': 'The team consists of four Europeans and two Americans.',
  'cut down': `I’ve decided to cut down on the amount of sweets I eat.`,
  'cut off (sb/sth) or cut (sb/sth) off': 'I almost got into an accident when someone cut me off',
  'do without': `She didn’t get a salary this month, so she’ll have to do without extra treats.`,
  'dress up': 'Their wedding gave us a chance to dress up and get out of the house.',
  'end up': `If you don’t improve your work habits, you’ll end up being fired.`,
  'face up to sth': 'You should grow up and face up your responsibilities',
  'fall out': `I’d fallen out with my parents.`,
  'fill in sth': '',
  'find out': `I’m going to to find out who’s responsible for the power cut.`,
  'fit in with sth': `It's a very nice sofa but it doesn't fit in with the rest of the room.`,
  'get away': `I'm been working a lot lately, I need to get away for a few days`,
  'get down to sth': `Now that we've all introduced ourselves, let's get down to business`,
  'get in': 'Our plane will get in late',
  'get into sth': 'She got into Parliament at the age of 26.',
  'get out of': `I said I wasn't feeling well and got out of the extra work`,
  'get over': `It took him years to get over the shock of his wife's death`,
  'get through sth': 'She got through her exams without too much trouble.',
  'go ahead': 'Go ahead and eat before everything gets cold',
  'go for sth': 'Instead of butter, I always go for margarine or a low fat spread',
  'go off': 'I went off the idea of buying a sports car after I found out how much it would cost.',
  'go on': `What the hell's going on?!`,
  'go over sth': 'Remember to go over your essay for grammar and spelling mistakes before you send it to me.',
  'go through': `You wouldn't believe what I went through when I was ill`,
  'go up': 'The average cost of a new house has gone up by five percent to £276,500.',
  'hang around (with sb)': 'Marie always hung around with boys.',
  'keep on': 'Mark needs to concentrate more when he works. He keeps on making basic mistakes',
  'keep sb in': 'They kept her in overnigh<t for observation.',
  'keep to sth': 'I think we should keep to our original plan.',
  'keep up (with sb/sth)': 'I read the paper every day to keep up with the news.',
  'keep up (with sth)': `You run too fast – I can’t keep up.`,
  'let off sb or let sb off': 'Instead of a prison sentence they were let off with a fine.',
  'live up to': 'The movie was excellent. It lived up to all our expectations.',
  'look after': 'I had to look after the neighbbours dog while they were away',
  'look at sth/sb': 'Did you get the doctor to look at your knee?',
  'look for sth/sb': 'Harry went to the shop to look for a new computer.',
  'look forward': 'She had been working hard and was looking forward to a year-end bonus.',
  'look into': 'He looked into hiring another secretary, but it would have been too expensive.',
  'look up to sb': 'He had always looked up to his grandfather.',
  'look up': `If you can't figure out whay a word MediaSession, look it up in the dictionary`,
  'make out sth/sb or make sth/sb out': `I couldn’t make out what he said.`,
  'make up for': 'He bought her some flowers and a cake to make up for being late.',
  'miss out on sth': `Don't miss out on the fantastic bargains in our summer sale.`,
  'pick up sth or pick sth up': 'She picked up a few German phrases while staying in Berlin.',
  'pull away': 'There was a roar and a cloud of smoke as the car pulled away from the traffic lights.',
  'pull on': 'Emily pulled on her gloves as she walked.',
  'pull up': 'Why do police cars in the US pull up behind the car they are stopping while in the UK the police pull up in front of the stopped car?',
  'put off sb or put sb off': `Robert’s attitude towards women really puts me off.`,
  'put together': `My son put together a robot two days ago and now it's his favourite toy`,
  'put up sth': 'One of the few athletes who put up some resistance to the Germans was the Polish athlete',
  'put up with': `I can't put up with my neighbour noise any longer. It's driving me mad!`,
  'save up (sth)': 'It took me months to save up enough money to go travelling.',
  'send off sth or send sth off': 'She sent the manuscript off to her publisher.',
  'set off': `I'm excited to get away! What time should we set off for the airport tomorrow`,
  'slip on': '',
  'slip out': 'Are you going to let him slip out of your hands or are you going to do something?',
  'stand out': 'We had lots of good applicants for the job, but one stood out from the rest.',
  'start out': 'My dad started out as a salesperson in a shop.',
  'stop over': 'We stopped over in Paris on our way to Cairo.',
  'take after': 'He takes after his farther in his fishing skills.',
  'take back sth or take sth back': 'The dress didn’t fit, so she took it back to the store.',
  'take off': '',
  'take out': 'The dentist took out all of my wisdom teeth before they started causing any problems.',
  'talk sb into (doing) sth': 'Dude, I tried talking her into calling you but she wasn’t having it.',
  'tell apart': 'The twins are so similar that only their mother can tell them apart.',
  'tell off sb or tell sb off': 'regañar / criticar a alguien',
  'tell off': 'Rob told off his boss, then quit.',
  'turn out': 'It turns out that she had known him when they were children.',
  'work on sth': 'His dancing technique is good, but he needs to work on his stamina.',
  'work sth out': `Have you worked out what're you going to do about your broken laptop?`,
}

export const CARD_STATE = {
  FACE_UP: 'FACE_UP',
  FACE_DOWN: 'FACE_DOWN',
  SOLVED: 'SOLVED',
}

export const BOARDS_LIST = {
  clothes: 'Clothes',
  travelling: 'Travelling',
  emotions: 'Emotions',
  family: 'Family/friends',
  motivation: 'Motivation, improving and positive thinking',
  work: 'Work',
  movement: 'Movement and space',
  shopping: 'Shopping',
  skills: 'Skills and actions'
};

export const BOARDS_DESCRIPTIONS = {
  'emotions': 'Emotions types or things that produce emotions'
};

export const APP_ID = 'phasalverbsmemorygame';
// export const API_URL = 'http://localhost:3000';
export const API_URL = 'https://records.davidinformatico.com';