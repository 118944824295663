export const START_GAME = 'START_GAME';
export const SHOW_CARD = 'SHOW_CARD';
export const HIDE_ALL_CARDS = 'FACE_DOWN_ALL_CARDS';
export const REMOVE_CARD = 'REMOVE_CARD';
export const CHANGE_BOARD = 'CHANGE_BOARD';
export const SET_COUNTER = 'SET_COUNTER';
export const SET_LAST_WORD = 'SET_LAST_WORD';
export const SET_USERNAME = 'SET_USERNAME';
export const TOGGLE_SCORE_BOARD = 'TOGGLE_SCORE_BOARD';
export const SET_END_GAME = 'SET_END_GAME';
export const SET_NUM_EXPOSED_CARDS = 'SET_NUM_EXPOSED_CARDS';